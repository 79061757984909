@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

.center {
  @apply flex justify-center items-center;
}

a {
  @apply text-blue-400 hover:underline;
}